import React, {useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { saveAs } from "file-saver";
const XLSX = require('xlsx');

function GroupedInventory({CustomFilter, setCustomfilter, activeTab, setActiveTab, InventoryData,scanResults, scannedProduct}) {

  const groupedData = useMemo(() => {
    const grouped = InventoryData.reduce((acc, item) => {
        if (!acc[item.productName]) {
            acc[item.productName] = { ...item, quantity: 0, Scanned: 0, Unscanned: 0, Total: 0, totalPrice: 0 };
        }
        acc[item.productName].quantity += 1;
        acc[item.productName].Total += 1;
        acc[item.productName].totalPrice += item.unitPrice; 

      
        if (scanResults.some(result => result.IMEI === item.IMEI[0])) {
            acc[item.productName].Scanned += 1;
        } else {
            acc[item.productName].Unscanned += 1;
        }

        return acc;
    }, {});
    return Object.values(grouped);
}, [InventoryData, scanResults]);




let columns = [
  { header: "Product Name", accessorKey: "productName", enableEditing: false },
  { header: "Condition", accessorKey: "condition", enableEditing: false },
  { header: "Total Price", accessorKey: "totalPrice", enableEditing: false },
  // { header: "Unit Price", accessorKey: "unitPrice", enableEditing: false },
  { header: "Quantity", accessorKey: "quantity", enableEditing: false },
  { header: "Scanned", accessorKey: "Scanned", enableEditing: false },
  { header: "Unscanned", accessorKey: "Unscanned", enableEditing: false },
  { header: "Total", accessorKey: "Total", enableEditing: false },
];

      const handleExportRows = (rows) => {
        console.log("exporting ...")
        const mappedRows = rows.map((row) => {
          const R = { ...row._valuesCache };
          return R;
        });
    
        const headerRow = columns.map((column) => column.header)
        const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
        const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const DATE = Date.now();
        saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "Inventory_Data_" + DATE + ".xlsx");
    
      };

  return (
    <div>
              <MaterialReactTable 
      data={groupedData} 
      columns={columns.map(c => ({...c,size: 5,minSize: 10}))} 
      
      positionToolbarAlertBanner="bottom"
      enableFullScreenToggle={false} 
      enableRowSelection={true}
      enableStickyHeader 
      editingMode="modal"
      // enableEditing
      // onEditingRowSave={handleSave}
      // Pagination
      enablePagination={true}
      initialState={{
        density: 'compact',
        pagination: { pageIndex: 0, pageSize: 10 },
        // showColumnFilters: true,
        // showGlobalFilter: true 
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
        showFirstButton: true,
        showLastButton: true,
        SelectProps: {
          native: true
        },
        labelRowsPerPage: 'Rows per pages'
      }}
      muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
      muiTableProps={{sx:{padding:'0px',background:'transparent',}}}
      muiTablePaperProps={{sx:{background:'transparent'}}}
      muiTableBodyCellProps={({cell,column})=> ({
      
        align:'center',
        sx:{
          border: '1px solid rgba(255, 255, 255, 1)', 
          borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
          color:'rgba(100,100,100,1)',
          background:( 
            cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
            ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
            ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
            ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OFF")? 'rgba(0, 255, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 81,0.5)': ''||
            ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="LOCKED ON VERIZON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON T-MOBILE")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON AT&T")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OOW")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="WARRANTY ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
            ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': '',
            padding: '8px 0px',
            

        }
      })}
      muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white', padding: '0px',   border: '1px solid rgba(255, 255, 255, 1)', },align:'center',}}
      muiTableHeadRowProps={{sx:{}}}
      muiTableBodyRowProps={({row}) => ({
        style: {
          backgroundColor: row.original.IMEI === scannedProduct ? '#dff0d8' : '', // Highlight with a blue color if selected
        }
      })}
      muiTableBodyProps={{sx:{background:'transparent'}}}
      muiTopToolbarProps={{sx:{background:'transparent'}}}
      muiBottomToolbarProps={{sx:{background:'transparent'}}}
      

      renderTopToolbarCustomActions={({ table }) => (
  <div className="flex gap-x-4">    
    <div className="mb-0">
      <input 
        className="bg-gray p-2 w-65 dark:bg-black rounded-md"
        placeholder="Search Product Name or IMEI" 
        onChange={(e) => setCustomfilter(e.target.value)}
        // disabled={Permission && !Permission.account} 
      />
      <button 
        className="rounded bg-logocolor text-white p-2 mx-1"
        // disabled={Permission && !Permission.account} 
        onClick={() => {
          table.setColumnFilters(prev => {
            if (isNaN(Number(CustomFilter))) {
              // If the input is not a number, filter by productName
              return [{ id: 'productName', value: CustomFilter }];
            } else {
              // If the input is a number, filter by IMEI
              return [{ id: 'IMEI', value: CustomFilter }];
            }
          });
        }}
      >
        Search
      </button>
    </div>
    <div className="flex">
              <button
                className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                variant="contained">
                <FileDownloadIcon />
                Export as XLSX
              </button>
              </div>
              <div className="flex">
              <button
                className={` ${ activeTab === 'grp' ? 'bg-logocolor text-white' : 'bg-bodydark1 text-graydark' }  px-5 py-1  rounded-l-md transition-all active:bg-black`}
                onClick={() => setActiveTab('grp')}
                variant="contained">
                Grouped
              </button>
              <button
               className={` ${ activeTab === 'idvl' ? 'bg-logocolor text-white' : 'bg-bodydark1 text-graydark' }  px-5 py-1  rounded-r-md transition-all active:bg-black`}
                onClick={() => setActiveTab('idvl')}
                variant="contained">
                Individual
              </button>
              </div>
  </div>
)}
      />
    </div>
  )
}

export default GroupedInventory
import React, { useEffect, useState } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import Breadcrumb from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { set_user_Data } from "../States/User";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from "../components/PackageBanner/Banner";
import { Link } from "react-router-dom";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import TimezoneSelect from  'react-timezone-select';
import moment from 'moment-timezone';



const Profile = () => {
  
  const userdata = useSelector((state) => state.user.value);

  const [First_Name, setFirstName] = useState(userdata.First_Name);
  const [Last_Name, setLastName] = useState(userdata.Last_Name);
  const [Phone, setPhone] = useState(userdata.Phone);
  const [Password, setPassword] = useState(userdata.Password);
  const [confirmPassword, setConfirmPassword] = useState(userdata.Password);
  const [timeZone, setTimeZone] = useState(userdata.timeZone); 
  const [dateFormat, setDateFormat] = useState(userdata.dateFormat); 
  const [userPermission, setUserPermission] = useState(false)
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [isEmptyField, setIsEmptyField] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Password !== confirmPassword) {
      toast.warn("Password don't match");
      return;
    }

    // if (!First_Name || !Last_Name || !Phone || !Password || !confirmPassword || !timeZone || !dateFormat) {
    //   setIsEmptyField(true);
    //   return;
    // }

    setIsEmptyField(false);

    try {
      const updatedData = {
        First_Name,
        Last_Name,
        Phone,
        timeZone,
        dateFormat
      };

      if (Password && confirmPassword) {
        updatedData.Password = Password;
      }

      const res = await axios.post(
        "https://api.cellercertified.com/Update_User_Profile",
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );

      if (res.data) {
        // Update Redux state or userdata with the new values
        const updatedUserData = {
          ...userdata,
          ...updatedData,
        };
        dispatch(set_user_Data(updatedUserData));

        // Store the updated data in local storage  
        localStorage.setItem("user", JSON.stringify(updatedUserData));
  
        toast.success('Profile updated successfully')
      }
    } catch (err) {
      console.log(err);
    }
  };
  async function Get_Sub_User_Permissions(){
    try{
        const res = await axios.get("https://api.cellercertified.com/dashboard/Sub_User_Permissions",{headers:{token:localStorage.getItem('token')}})
       
        const d =res?.data?.data;
    
        if(d===undefined) {return;}
        setUserPermission(d[0])
    }catch(e){}
    }
  
    useEffect(()=>{
      Get_Sub_User_Permissions()
    },[])
 

  return (
    <div className="leading-6">
         <DefaultLayout>
      <Banner/>
      <Breadcrumb pageName="Profile" />
      { userPermission && !userPermission.account&&(
          <div className=" fixed bg-[#D34053] text-white top-16 left-0 flex w-full justify-center">
              <div className="my-2  rounded-md danger p-1.5 dark:bg-meta-4">
              You dont have permission to access this page please upgrade you plan.
            </div>
          </div>)
        }
      <ToastContainer />
      <div className="profileForm min-h-screen w-5/6">
        <div className="">
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
              <h1 className="text-2xl font-bold text-black dark:text-white">
                Profile
              </h1>
            </div>
            <form action="#" onSubmit={handleSubmit}>
              <div className="p-6.5">
                {isEmptyField && (
                  <div className="mb-4 text-red-500">
                    All fields are required.
                  </div>
                )}
                <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                  <div className="w-full xl:w-1/2">
                    <input
                    disabled={userPermission && !userPermission.account}
                      type="text"
                      placeholder="First name"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={First_Name}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>

                  <div className="w-full xl:w-1/2">
                    <input
                     disabled={userPermission && !userPermission.account}
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      value={Last_Name}
                      placeholder="Last name"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>
                </div>
                <div className="mb-4.5 grid grid-cols-1 gap-6 md:grid-cols-2">
                  

                  <div className="w-full  ">
                    <input
                    disabled={userPermission && !userPermission.account}
                      type="text"
                      placeholder={userdata.Email}
                      readOnly
                      className="bg-gray-3 font-semibold pointer-events-none w-full rounded border-[1.5px] border-stroke  py-3 px-5  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>

                  <div className=" w-full h-12">
                 
                  <PhoneInput
                   disabled={userPermission && !userPermission.account}
                   inputStyle={{ width: '100%',height: '100%' }}
                    value={Phone}
                    onChange={(value) => {setPhone(value);}}
                    className="w-full h-full "
                    type="tel"
                  />
                </div>

                  

                </div>
                <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                  <div className="w-full xl:w-1/2">
                    <input
                    disabled={userPermission && !userPermission.account}
                      type="password"
                      placeholder="New Password"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>

                  <div className="w-full xl:w-1/2">
                    <input
                     disabled={userPermission && !userPermission.account}
                      type="password"
                      value={confirmPassword}
                      placeholder="Confirm New Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    />
                  </div>

                </div>
                <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full xl:w-1/2">
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            TimeZone
          </label>
          <TimezoneSelect
            disabled={userPermission && !userPermission.account}
            value={timeZone}
            onChange={(selected) => setTimeZone(selected.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          />
        </div>

        <div className="w-full xl:w-1/2">
          <label className="block text-sm font-medium text-gray-700 dark:text-white">
            DateFormat
          </label>
          <select
            disabled={userPermission && !userPermission.account}
            value={dateFormat}
            onChange={(e) => setDateFormat(e.target.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-5 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          >
            {/* Add options for DateFormat */}
            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
            <option value="MM-DD-YYYY">MM-DD-YYYY</option>
            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
            <option value="DD-MM-YYYY">DD-MM-YYYY</option>
            <option value="YYYY/MM/DD">YYYY/MM/DD</option>
            <option value="YYYY-MM-DD">YYYY-MM-DD</option>
            <option value="MMMM DD, YYYY">MMMM DD, YYYY</option>
            <option value="DD MMMM, YYYY">DD MMMM, YYYY</option>
            {/* Add more options as needed */}
          </select>
        </div>

                </div>
                
                <button  disabled={userPermission && !userPermission.account} className="flex w-full justify-center rounded p-3 font-bold text-gray bg-meta-5 hover:bg-darkblue cursor-pointer">
                  Update Your Profile
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </DefaultLayout>
    </div>
  );
};

export default Profile;

import axios from "axios";
import React, { useEffect,  useState,useRef } from "react"
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PushAddationalFields } from "../../scripts/addational_fields";
import {  CircularProgress, IconButton,} from '@mui/material';
import {   Add, Close, CopyAll, DeleteForever, Edit,    MoreHoriz,    Print,   QrCodeScannerTwoTone } from '@mui/icons-material';
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { copy_txt } from "../../scripts/services";
import {Barcode,pageStyle} from "../Bar_Code_Template";
import ReportTemplate from "../Report_Template";
import SimpleModal from "../Simple_Modal";
import { Modal } from "antd";
import { saveAs } from "file-saver";
import Select from 'react-select'
import { Button } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import EditCustomFields from "../Edit_Custom_Fields";
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment-timezone';
import { NavLink } from "react-router-dom";
import DefaultLayout from "../../layout/DefaultLayout";
import Banner from "../PackageBanner/Banner";
import Breadcrumb from "../Breadcrumb";
import { useLocation } from 'react-router-dom';
const XLSX = require('xlsx');


const ToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 11,
    lineHeight: '1.5rem'
  },
}));



const IMEIHistory = ({Permission}) => {
  const userdata = useSelector((state) => state.user.value);
  const [Delete_Permission,set_delete_permission] = useState(false)
  const [Single_Bar_Code_Data,Set_Single_Bar_Code_Data] = useState({})
  const [information,set_Information]= useState('')
  const [Multi_Bar_Code_Data,Set_Multi_Bar_Code_Data] = useState([])
  const [print_report_data,set_print_report_data] = useState({})
  const [Rows_To_Edit,Set_Rows_To_Edit] = useState({});
  const [Catageory,Set_Catageory] =useState("BATTERY_HEALTH");
  const [Bulk_Edit_Data,Set_Bulk_Edit_Data] = useState("")
  const [Bulk_Edit_Res,Set_Bulk_Edit_Res] = useState("")
  const [Delete_Input_Text,Set_Delete_Input_Text] = useState("")
  const [Delete_State,set_Delete_State] = useState(0)
  const ref  = useRef();
  const ref2  = useRef();
  const Report_Ref = useRef();
  const [IMEIHistoryData, setIMEIHistoryData] = useState([]);
  const [CustomFilter,setCustomfilter] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const showCustomValue =
  selectedRows.length > 0 && selectedRows[0].original
    ? selectedRows[0].original
    : "Empty";
  const [Bulk_Edit_Input, setBulk_Edit_Input] = useState({
    BATTERY_HEALTH: '',
    GRADE: '',
    COSMETIC_GRADE: '',
    FUNCTIONAL_GRADE: '',
    PRICE: '',
    COLOR: '',
    NOTES: '',
    CUSTOMER_ID: '',
    INTERNAL_REFERENCE_ID : "",
  });
  const [CustomEdit_Input, setCustomEdit_Input] = useState("");
  const [CustomEdit_Res, setCustomEdit_Res] = useState("");
  const [reportTitle, setReportTitle] = useState('');
  const location = useLocation();
  
  const poNumberProp = location.state

  
  // information modal 
  const [isModalOpen, setIsModalOpen] = useState(false);


  const showModal = (row) => {
    
    // console.log(row)
    setIsModalOpen(true);
    set_Information(row?.original)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  let token = localStorage.getItem('token');

  const [Bulk_Edit_State,setBulk_Edit_State] = useState(0)
  const get_User_Reports = async () => {
    try {
      const res = await axios.get("https://api.cellercertified.com/get-scan-reports", {
        headers: { token },
      });
      // console.log(res)
      const reversedData = res.data.data.reverse();
      // console.log(reversedData)

      setIMEIHistoryData(reversedData);
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
  };
  console.log(IMEIHistoryData)

  useEffect(() => {

    get_User_Reports();
   
  }, []);


  let columns = [
    {header: "ID",accessorKey: "UID",enableEditing: false,Cell:({row}) => row.index+1},
    {header: "Title",accessorKey: "title",enableEditing: false, Cell: ({ row }) =>
      <NavLink to="/scanResults" state={row.original}>
      <label className="hover:cursor-pointer hover:underline text-logocolor py-1 px-5" >{row.original.title}</label>
    </NavLink>,},
    {header: "Type",accessorKey: "poNumber",enableEditing: false, Cell: ({ row }) =>
      <NavLink to={`/poDetails/${row.original.poNumber}`} state={row.original.poNumber}>
      <label className="hover:cursor-pointer hover:underline text-logocolor py-1 px-5" >{row.original.poNumber}</label>
    </NavLink>,},
    {header: "Date",accessorKey:"date" ,enableEditing: false},
  ]
    
  return (
    <div className='leading-6'>
    <DefaultLayout>
        <Banner/>
        <Breadcrumb pageName="Scan Reports"/>
    <div className="rounded-sm border border-stroke bg-white px-5 pt-4 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 xl:pb-5">
      <h4 className="mb-4 text-xl font-semibold text-black dark:text-white">
        Scan Reports
      </h4>
      <MaterialReactTable 
      data={IMEIHistoryData} 
      columns={columns.map(c => ({...c,size: 5,minSize: 10}))} 
      positionToolbarAlertBanner="bottom"
      enableFullScreenToggle={false} 
      enableRowSelection={Permission ? !Permission.account : false}
      enableStickyHeader 
      editingMode="modal"
      enableEditing
      enableGlobalFilter={false}
      // onEditingRowSave={handleSave}
      // Pagination
      enablePagination={true}
      initialState={{
        density: 'compact',
        pagination: { pageIndex: 0, pageSize: 10 },
        // showColumnFilters: true,
        // showGlobalFilter: true 
      }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
        showFirstButton: true,
        showLastButton: true,
        SelectProps: {
          native: true
        },
        labelRowsPerPage: 'Rows per pages'
      }}
      muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
      muiTableProps={{sx:{padding:'0px',background:'transparent',}}}
      muiTablePaperProps={{sx:{background:'transparent'}}}
      muiTableBodyCellProps={({cell,column})=> ({
      
        align:'center',
        sx:{
          border: '1px solid rgba(255, 255, 255, 1)', 
          borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
          color:'rgba(100,100,100,1)',
          background:( 
            cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
            ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
            ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
            ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OFF")? 'rgba(0, 255, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 81,0.5)': ''||
            ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="LOCKED ON VERIZON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON T-MOBILE")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED ON AT&T")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="OOW")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="WARRANTY ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="ACTIVE")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
            ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': '',
            padding: '8px 0px',
            

        }
      })}
      muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white', padding: '0px',   border: '1px solid rgba(255, 255, 255, 1)', },align:'center',}}
      muiTableHeadRowProps={{sx:{}}}
      muiTableBodyRowProps={{sx:{}}}
      muiTableBodyProps={{sx:{background:'transparent'}}}
      muiTopToolbarProps={{sx:{background:'transparent'}}}
      muiBottomToolbarProps={{sx:{background:'transparent'}}}



      renderRowActions={({ row, table }) =>
      
       { 
        console.log(row.original)
        return (
        <span className="" >
        <ToolTip title="Veiw Scan Results">
        <IconButton>
        <NavLink to="/scanResults" state={row.original}>
        <MoreHoriz className="text-logocolor"/>
        </NavLink>
        </IconButton>
        </ToolTip>
        </span>)}}




      renderTopToolbarCustomActions={({ table }) => (
        <div >    

        <div className="mb-0 ">
        <input 
        className="bg-gray p-2  dark:bg-black rounded-md"
        placeholder="Search Title" 
        onChange={(e) => setCustomfilter(e.target.value)}
        disabled={Permission && !Permission.account} 
        >
        </input>
        <button 
        className="rounded bg-logocolor text-white p-2 mx-2"
        disabled={Permission && !Permission.account} 
        onClick={()=>{table.setColumnFilters(prev => [{id: 'title',value: CustomFilter}])}}>Search</button>
        </div>

        </div>

       )}
      />
      </div>
</DefaultLayout>
</div>
  );
};

export default IMEIHistory;
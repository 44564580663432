import React, {useState, useEffect} from 'react';
import {MaterialReactTable} from 'material-react-table';
import axios from 'axios';

function BatchReports() {
    const [batchReportsData, setBatchReportsData] = useState([])

    let token = localStorage.getItem('token');



    const getBatchReports = async () => {
        try {
          const res = await axios.get("https://api.cellercertified.com/get-all-batchreports", {
            headers: { token },
          });
          console.log((res))
        //   const reversedData = res.data.reverse();

          setBatchReportsData(res.data.data);
        } catch (error) {
          console.error("Error fetching user reports:", error);
        }
      };

      useEffect(()=> {
        getBatchReports();
      }, [])



      let columns = [
      
        {header: "Batch Report ID",accessorKey: "BatchReportID",enableEditing: false},
        {header: "Customer ID",accessorKey: "CustomerID",enableEditing: false},
        {header: "Total IMEI Checked",accessorKey: "UID",enableEditing: false,Cell:({row}) => row.original.Data.length},
        {header: "Batch Report Link",accessorKey: "UID",enableEditing: false,Cell:({row}) => 
            <a className="hover:underline text-logocolor " href={`https://cellercertified.com/batchReport?BatchReportID=${row.original.BatchReportID}`} rel="noreferrer" target="_blank">Link</a>,},
      ]
      console.log(batchReportsData)
  return (

         <div>
            <MaterialReactTable data={batchReportsData} columns={columns.map(c => ({...c,size: 5,minSize: 10}))}
             positionToolbarAlertBanner="bottom"
      // initialState={{density: 'compact' }}
      enableBottomToolbar={false}
      enableTopToolbar={false} 
      enableColumnActions={false}      
      enableDensityToggle={false} 
      enableFullScreenToggle={false} 
      enableFilters={false} 
      enablePagination={false}
      enableRowSelection={false}
      enableSorting={false}

        
      muiTableBodyCellEditTextFieldProps={{variant: 'filled'}} 
      
      muiTableContainerProps={{sx:{background:'transparent',borderRadius:'8px', }}}
      muiTableProps={{sx:{padding:'',background:'transparent'}}}
      muiTablePaperProps={{sx:{background:'transparent'}}}
      muiTableBodyCellProps={({cell,column})=> ({
        
        align:'center',
        padding: '0px',
       
        sx:{
          border:'0px', 
          borderBlockEnd:'1px solid rgba(0,0,0,0.1)',
          color:'rgba(100,100,100,1)',
          fontSize: '12px',
          padding: '8px',
          background:( 
            cell.getValue() ==="CLEAN")?'rgba(0, 255, 81,0.5)':''|| 
            ( cell.getValue() ==="BAD")?'rgba(255, 0, 25,0.4)':''|| 
            ( cell.getValue() ==="BLACKLISTED")?'rgba(255, 0, 25,0.4)':''||
            ( cell.getValue() ==="OFF")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="ON")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LOCKED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="UNLOCKED")? 'rgba(0, 255, 81,0.5)': ''||
            ( cell.getValue() ==="OUT OF WARRANTY")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="WARRANTY EXPIRED")? 'rgba(255, 0, 25,0.4)': ''||
            ( cell.getValue() ==="LIMITED WARRANTY")? 'rgba(244, 255, 151, 0.8)': ''||
            ( cell.getValue() ==="APPLE CARE")? 'rgba(0, 255, 81,0.5)': ''

        }
      })}
      muiTableHeadCellProps={{sx:{ background:'rgb(0 174 239)',color:'white',border: '1px solid white',fontSize: '12px',},align:'center'}}
      muiTableHeadRowProps={{sx:{}}}
      muiTableBodyRowProps={{sx:{background:'transparent'},hover:false}}
      muiTableBodyProps={{sx:{background:'transparent'}}}
      muiTopToolbarProps={{sx:{background:'transparent'}}}
      muiBottomToolbarProps={{sx:{background:'transparent'}}}
             />
         </div>
  )
}

export default BatchReports
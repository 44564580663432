import axios from "axios";
import React, { useEffect, useState, useRef } from "react"
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PushAddationalFields } from "../scripts/addational_fields";
import { CircularProgress, IconButton, } from '@mui/material';
import { Add, Close, CopyAll, DeleteForever, Edit, Print, QrCodeScannerTwoTone } from '@mui/icons-material';
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import { copy_txt } from "../scripts/services";
import { Barcode, pageStyle } from "./Bar_Code_Template";
import ReportTemplate from "./Report_Template";
import SimpleModal from "./Simple_Modal";
import { Modal } from "antd";
import { saveAs } from "file-saver";
import Select from 'react-select'
import { Button } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import EditCustomFields from "./Edit_Custom_Fields";
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment-timezone';
import { NavLink } from "react-router-dom";
import DefaultLayout from "../layout/DefaultLayout";
import Banner from "./PackageBanner/Banner";
import Breadcrumb from "./Breadcrumb";
import { useLocation, useNavigate } from 'react-router-dom';
const XLSX = require('xlsx');


const ToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 11,
    lineHeight: '1.5rem'
  },
}));



const IMEIHistory = ({ Permission }) => {
  const userdata = useSelector((state) => state.user.value);
  const location = useLocation();
  const navigate = useNavigate();

  const poNumberProp = location.state.poNumber 
  const hash = location.hash;
  const mainId = hash.split('/id=')[1];
  const [productData, setProductData] = useState([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState([])
  const [CustomFilter, setCustomfilter] = useState([])
  const [modalTitle, setModalTitle] = useState(`${poNumberProp} - Scan Report`);
  const [loading, setLoading] = useState(false);
  const [Rows_To_Edit, Set_Rows_To_Edit] = useState({});
  const [Bulk_Edit_State, setBulk_Edit_State] = useState(0)
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [showTotalPrice, setShowTotalPrice] = useState(false);
  const [Bulk_Edit_Input, setBulk_Edit_Input] = useState({
    BATTERY_HEALTH: '',
    GRADE: '',
    COSMETIC_GRADE: '',
    FUNCTIONAL_GRADE: '',
    PRICE: '',
    COLOR: '',
    NOTES: '',
    CUSTOMER_ID: '',
    INTERNAL_REFERENCE_ID: "",
  });
  const [customFields, setCustomFields] = useState({
    productName: '',
    quantity: '',
    unitPrice: '',
    condition: '',
  });



  // console.log("location", location, "main id", mainId)

  let token = localStorage.getItem('token');

  const get_User_Reports = async () => {
    try {
      const res = await axios.get("https://api.cellercertified.com/get-purchase-order", {
        headers: { token },
      });
      console.log(res)
      const reversedData = res.data.data.reverse();
      console.log(reversedData)
      const matchingPO = reversedData.find(item => item.poNumber === poNumberProp);
      if (matchingPO) {
        console.log("Matching PO found:", matchingPO.products);

      } else {
        console.log("No matching PO found");
      }
      const productsArrays = reversedData.map(purchaseOrder => purchaseOrder.products);
      console.log(productsArrays)
      setPurchaseOrderData(matchingPO)
      setProductData(matchingPO.products);
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
  };
  console.log(productData)
  useEffect(() => {
    get_User_Reports();

  }, []);




  let columns = [
    { header: "Product Name", accessorKey: "productName", enableEditing: false },
    { header: "IMEI", accessorKey: "IMEI", enableEditing: false, Cell: ({ cell }) => <div>{cell.getValue().map((imei, index) => <p key={index}>{imei}</p>)}</div> },
    { header: "Condition", accessorKey: "condition", enableEditing: false },
    { header: "Unit Price", accessorKey: "unitPrice", enableEditing: false },
    { header: "Quantity", accessorKey: "quantity", enableEditing: false },
    ...(showTotalPrice ? [{ 
      header: "Total Price", 
      accessorKey: "totalPrice", 
      enableEditing: false, 
      Cell: ({ cell }) => <div>{cell.row.original.quantity ? cell.row.original.unitPrice * cell.row.original.quantity : ""}</div> 
    }] : [])
  ];


  const createScanReport = async () => {
    try {
      setLoading(true);
      const currentDate = new Date().toISOString();
      const dataToSend = {
        title: modalTitle,
        date: currentDate,
        Data: purchaseOrderData
      };
      const res = await axios.post("https://api.cellercertified.com/create-scan-reports", dataToSend, {
        headers: { token }
      });
      console.log("Scan report created:", res.data);
      // alert('ScanReport Created!, Check on Scan Reports page.')
      navigate('/scanResults', { state: { title: modalTitle, poNumber: poNumberProp, products: productData } })
      setLoading(false);
      setModalTitle("");
    } catch (error) {
      console.error("Error creating scan report:", error);
      setLoading(false);
    }
  };


  const handleModalInputChange = (e) => {
    setModalTitle(e.target.value);
  };

  const handleExportRows = (rows) => {
    console.log("exporting ...")
    const mappedRows = rows.map((row) => {
      const R = { ...row._valuesCache };
      return R;
    });

    const headerRow = columns.map((column) => column.header)
    const data = [headerRow, ...mappedRows.map((row) => Object.values(row))];

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const buffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const DATE = Date.now();
    saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), "Report_History_" + DATE + ".xlsx");

  };


  async function Bulk_Edit_Rows() {
    if (Object.keys(Bulk_Edit_Input).length === 0) {
      toast.error("No Data");
      return;
    }

    // Set Bulk_Edit_State to indicate loading
    setBulk_Edit_State(1);

    // Collect IDs in an array format
    const UIDs = Rows_To_Edit.map((row) => row.original._id);

    if (UIDs.length === 0) {
      toast.error("No products selected");
      setBulk_Edit_State(0);
      return;
    }
    try {
      const editedFields = {
        productName: customFields.productName,
        quantity: customFields.quantity,
        unitPrice: customFields.unitPrice,
        condition: customFields.condition,
      };

      for (const property of selectedProperties) {
        editedFields[property] = Bulk_Edit_Input[property];
      }

      console.log("Edited Fields:", editedFields); // Debugging line

      const res = await axios.post(
        "https://api.cellercertified.com/bulkUpdateProducts",
        // "http://localhost:8000/bulkUpdateProducts",
        { mainId, ids: UIDs, products: editedFields },
        { headers: { token } }
      );


      if (res.data.status === true) {
        toast.success("Products have been updated");
        get_User_Reports();
        setBulk_Edit_State(0);
      } else {
        toast.error("Unable to update products");
      }

      // get_User_Reports();
      // setBulk_Edit_State(0);

    } catch (error) {
      console.error("Error:", error); // Debugging line
      setBulk_Edit_State(0);
      toast.error("An error occurred while updating products");
    }
  }
  const handelSearch = async () => {
    const filtered = productData.filter(item => item.productName.toLowerCase() === CustomFilter.toLowerCase() || item.IMEI === CustomFilter);
    setProductData(filtered);
  }

console.log(location.state)
  return (
    <div className='leading-6'>
      <DefaultLayout>
        <Banner />
        <Breadcrumb pageName="Purchase Order Details" parentName="Purchase Orders" parentLink="/purchase-orders" />
        <div className="rounded-sm border border-stroke bg-white px-5 pt-4 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-3.5 xl:pb-5">
          <h4 className="mb-4 text-xl font-semibold text-black dark:text-white">
            Purchase Order {poNumberProp} from {location.state.vendor}
          </h4>

          <div>
            <input
              disabled={Bulk_Edit_State === 0 ? false : true}
              type="checkbox" id="my-modal2" className="modal-toggle" />

            <div className={`modal z-999 `}>
              <div className="w-[38%] h-[50%] p-5 bg-white dark:bg-black-2 rounded-xl relative">
                <div className="flex justify-between ">
                  <h1 className="font-bold text-black-2 dark:text-white text-xl flex items-center gap-x-2 uppercase">Update Products <Add className="text-[#1DA8DF]" /></h1>
                  {/* <h1>{Rows_To_Edit.length} Report(s) Editing</h1> */}
                </div>
                <div className="flex flex-col justify-between my-2">
                  <h1>Enter Values in Fields to Add</h1>
                  <CHECK_BOX_ITEM2
                    name="Product Name"
                    setCustomFields={(value) => setCustomFields((prev) => ({ ...prev, productName: value }))}
                    customFields={customFields.productName}
                  />
                  <CHECK_BOX_ITEM2
                    name="Condition"
                    setCustomFields={(value) => setCustomFields((prev) => ({ ...prev, condition: value }))}
                    customFields={customFields.condition}
                  />
                  <CHECK_BOX_ITEM2
                    name="Unit Price"
                    setCustomFields={(value) => setCustomFields((prev) => ({ ...prev, unitPrice: value }))}
                    customFields={customFields.unitPrice}
                  />
                  <CHECK_BOX_ITEM2
                    name="Quantity"
                    setCustomFields={(value) => setCustomFields((prev) => ({ ...prev, quantity: value }))}
                    customFields={customFields.quantity}
                  />
                </div>



                <div className="flex gap-x-2 justify-end absolute bottom-2 right-5">
                  <button
                    onClick={() => Bulk_Edit_Rows()}
                    disabled={Bulk_Edit_State === 0 ? false : true}
                    className='p-2 rounded-md my-1 h-full bg-[#1DA8DF] w-20  dark:bg-black border-0 text-white'>Update</button>
                  <label
                    disabled={Bulk_Edit_State === 0 ? false : true}
                    onClick={() => { setSelectedProperties([]) }}
                    htmlFor="my-modal2" className='p-2 cursor-pointer rounded-md my-1 h-full bg-danger w-20 text-center dark:bg-black border-0 text-white' >Cancel</label>
                </div>



                {/*<div className={`${Bulk_Edit_State === 1 ? 'block' : 'hidden'}  p-2 flex items-center`}>
                    <CircularProgress size={20} thickness={8} />
                    <h1 className="mx-2 italic">Updating ... </h1>
      </div>*/}

                {/*<div className={`${Bulk_Edit_State === 2 ? 'block' : 'hidden'} flex`}>
                    <h1>{Bulk_Edit_Res}</h1>
                    <label htmlFor="my-modal2" className="mx-2 underline cursor-pointer" onClick={() => { setBulk_Edit_State(0) }}>Close</label>
                    <h1>{Rows_To_Edit.length} Report(s) Updated Successfully</h1>
      </div>*/}

              </div>
            </div>
          </div>


          {/* Create Scan Report Modal */}
          <div>
            <input
              type="checkbox" id="my-modal3" className="modal-toggle" />
            <div className={`modal z-999`}>
              <div className="modal-box bg-white dark:bg-black-2">
                <h1 className="font-bold flex items-center gap-x-1 text-black-2 dark:text-white text-lg">New Scan Report <Add className="text-logocolor mb-1" /></h1>
                <h1>To create Scan Report enter Title</h1>


                <div className="my-4">
                  <h1 className="text-sm my-1 tracking-widest"> <strong>TITLE</strong></h1>
                  <input
                    value={modalTitle}
                    onChange={handleModalInputChange}
                    className="p-2  w-full bg-white rounded-md border"
                    placeholder="Enter Title "></input>
                </div>

                <div>
                  <button
                    onClick={createScanReport}
                    className='btn btn-sm my-1 mx-2 h-full bg-meta-5 w-20  dark:bg-black border-0 text-white'>Create</button>
                  <label
                    htmlFor="my-modal3" onClick={() => setModalTitle(`${poNumberProp} - Scan Report`)} className='btn btn-sm my-1 mx-2 bg-danger  dark:bg-black border-0 text-white'
                  >Cancel</label>
                </div>

                {loading &&
                  <div className={`p-2 flex items-center`}>
                    <CircularProgress size={20} thickness={8} />
                    <h1 className="mx-2 italic">Creating ... </h1>
                  </div>
                }

                <div className={`flex`}>
                  <label htmlFor="my-modal3" onClick={() => setModalTitle(`${poNumberProp} - Scan Report`)} className="mx-2 underline cursor-pointer">Close</label>
                </div>

              </div>
            </div>
          </div>

          <div className="flex justify-center underline text-sm text-darkblue ">

          </div>
          <MaterialReactTable
            data={productData}
            columns={columns.map(c => ({ ...c, size: 5, minSize: 10 }))}
            positionToolbarAlertBanner="bottom"
            enableFullScreenToggle={false}
            enableRowSelection={Permission ? !Permission.account : true}
            enableStickyHeader
            editingMode="modal"
            enableEditing={false}
            enableGlobalFilter={false}
            enablePagination={true}
            initialState={{
              density: 'compact',
              pagination: { pageIndex: 0, pageSize: 10 },

            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [10, 25, 50, 100, 200, 250, 500],
              showFirstButton: true,
              showLastButton: true,
              SelectProps: {
                native: true
              },
              labelRowsPerPage: 'Rows per pages'
            }}
            muiTableBodyCellEditTextFieldProps={{ variant: 'filled' }}
            muiTableContainerProps={{ sx: { background: 'transparent', borderRadius: '8px', } }}
            muiTableProps={{ sx: { padding: '0px', background: 'transparent', } }}
            muiTablePaperProps={{ sx: { background: 'transparent' } }}
            muiTableBodyCellProps={({ cell, column }) => ({

              align: 'center',
              sx: {
                border: '1px solid rgba(255, 255, 255, 1)',
                borderBlockEnd: '1px solid rgba(0,0,0,0.1)',
                color: 'rgba(100,100,100,1)',
                background: (
                  cell.getValue() === "CLEAN") ? 'rgba(0, 255, 81,0.5)' : '' ||
                    (cell.getValue() === "BAD") ? 'rgba(255, 0, 25,0.4)' : '' ||
                      (cell.getValue() === "BLACKLISTED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                        (cell.getValue() === "ON") ? 'rgba(255, 0, 25,0.4)' : '' ||
                          (cell.getValue() === "OFF") ? 'rgba(0, 255, 25,0.4)' : '' ||
                            (cell.getValue() === "LOCKED") ? 'rgba(255, 0, 81,0.5)' : '' ||
                              (cell.getValue() === "UNLOCKED") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                (cell.getValue() === "LOCKED ON VERIZON") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                  (cell.getValue() === "LOCKED ON T-MOBILE") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                    (cell.getValue() === "LOCKED ON AT&T") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                      (cell.getValue() === "OUT OF WARRANTY") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                        (cell.getValue() === "OOW") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                          (cell.getValue() === "WARRANTY ACTIVE") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                            (cell.getValue() === "ACTIVE") ? 'rgba(0, 255, 81,0.5)' : '' ||
                                              (cell.getValue() === "WARRANTY EXPIRED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                                (cell.getValue() === "EXPIRED") ? 'rgba(255, 0, 25,0.4)' : '' ||
                                                  (cell.getValue() === "LIMITED WARRANTY") ? 'rgba(244, 255, 151, 0.8)' : '' ||
                                                    (cell.getValue() === "APPLE CARE") ? 'rgba(0, 255, 81,0.5)' : '',
                padding: '8px 0px',


              }
            })}
            muiTableHeadCellProps={{ sx: { background: 'rgb(0 174 239)', color: 'white', padding: '0px', border: '1px solid rgba(255, 255, 255, 1)', }, align: 'center', }}
            muiTableHeadRowProps={{ sx: {} }}
            muiTableBodyRowProps={{ sx: {} }}
            muiTableBodyProps={{ sx: { background: 'transparent' } }}
            muiTopToolbarProps={{ sx: { background: 'transparent' } }}
            muiBottomToolbarProps={{ sx: { background: 'transparent' } }}


            renderTopToolbarCustomActions={({ table }) => (
              <div >

                <div className="mb-4 ">
                <input
            className="bg-gray p-2 dark:bg-black rounded-md"
            placeholder="Search Product Name"
            onChange={(e) => {
              const value = e.target.value;
              setCustomfilter(value);
              table.setColumnFilters([{ id: 'productName', value }]); // Apply the filter in real-time
            }}
            disabled={Permission && !Permission.account}
          />
                  <button
                    className="rounded bg-logocolor text-white p-2 mx-2"
                    disabled={Permission && !Permission.account}
                    onClick={handelSearch}
                  >Search</button>
                  <button
                    className="right-32 absolute py-2 text-white bg-logocolor  rounded-md transition-all active:bg-black"
                  >
                    <label htmlFor="my-modal3" className="hover:cursor-pointer py-1 px-5" > <Add />New Scan Report</label>
                  </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
                  <button
                    className="px-5 py-1 disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-logocolor  rounded-md transition-all active:bg-black"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                    variant="contained">
                    <FileDownloadIcon />
                    Export as XLSX
                  </button>
                  <button
                    className=" py-1  disabled:text-graydark disabled:bg-bodydark1 text-bodydark1 bg-meta-6  rounded-md transition-all active:bg-black"
                    disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                    onMouseOver={() => { Set_Rows_To_Edit(table.getSelectedRowModel().rows) }}
                  >
                    <label htmlFor="my-modal2" className="hover:cursor-pointer py-1 px-5" > <Edit />Bulk Edit</label>
                  </button>
                  <button
                    className={`${showTotalPrice === false ? "py-1 text-white rounded-md transition-all bg-logocolor" : "py-1 text-graydark bg-bodydark1 rounded-md transition-all active:bg-black"}`}
                    onClick={() => setShowTotalPrice(false)}
                  >
                    Individual
                  </button>
                  <button
                    className={`${showTotalPrice === true ? "py-1 text-white rounded-md transition-all bg-logocolor" : "py-1 text-graydark bg-bodydark1 rounded-md transition-all active:bg-black"}`}
                    onClick={() => setShowTotalPrice(true)}
                  >
                    Group
                  </button>
                </div>
              </div>

            )}
          />
        </div>
      </DefaultLayout>
    </div>
  );
};

export default IMEIHistory;



function CHECK_BOX_ITEM2({ name, setCustomFields, customFields }) {
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="flex items-center ">
        <label
          className="text-gray-900 dark:text-gray-300 h-10 w-full text-sm font-medium"
        >
          {name}
        </label>
      </div>
      <div className="">
        <input
          className="bg-white dark:bg-black border rounded w-full h-10 text-xs px-2"
          placeholder={`Enter ${name}`}
          // value={customFields}
          onChange={(e) => setCustomFields(e.target.value)}
        ></input>
      </div>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}